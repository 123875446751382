<template>
  <div class="slider" v-if="sliders.length > 0">
    <carousel
      :autoplay="sliders.length > 1"
      :loop="sliders.length > 1"
      :items="1"
      :stagePadding="sliders.length > 1 ? 500 : 0"
      :margin="sliders.length > 1 ? 28 : 0"
      :nav="false"
      :lazyLoad="true"
      :responsiveClass="true"
      :responsive="slideResponsive"
      :dots="false"
    >
      <template v-for="(item, key) in sliders">
        <a
          :href="item.url"
          :target="item.open_in === 'new' ? '_blank' : '_self'"
          :key="key"
        >
          <div class="slider__image">
            <img
              :src="item.image_url"
              :alt="item.image_name"
              :title="item.image_name"
              style="object-fit:cover;"
              class="bd-placeholder-img bd-placeholder-img-lg d-block w-100 h-auto"
            />
          </div>

          <div class="slider__desc">
            <span class="slider__headline" v-if="item.category">
              {{item.category}}
            </span>
            <p v-if="item.title">
              {{item.title}}
            </p>
          </div>
        </a>
      </template>
    </carousel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import carousel from "vue-owl-carousel";

export default {
  name: "slider",
  components: {
    carousel,
  },
  data() {
    return {
      sliders: [],
      slideResponsive: {
        0: {
          items: 1,
          stagePadding: 0,
          margin: 0,
        },
        600: {
          items: 1,
          stagePadding: 50,
          margin: 20,
        },
        1024: {
          items: 2,
          stagePadding: 250,
          margin: 28,
        },
        1280: {
          items: 2,
          stagePadding: 250,
          margin: 28,
        },
        1366: {
          items: 2,
          stagePadding: 250,
          margin: 28,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      topPage: "common/topPage",
    }),
  },
  created() {
    this.sliders = this.topPage.slider;
  },
  watch: {
    topPage(data) {
      this.sliders = data.slider;
    },
  },
};
</script>
