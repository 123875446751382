<template lang="">
    <div class="top-news">
      <div class="row">
        <div class="col-2">
            <h3 class="title">
              News
            </h3>
            <h2 class="sub-title">
              {{$t('top_page.list')}}
            </h2>
            <div class="show-more">
              <label>一覧</label>
              <router-link class="btn-arrow" :to="{ name: 'information'}">
                <span class="icon-arrow-right"></span>
              </router-link>
            </div>
        </div>
        <div class="col-10">
            <ul class="list">
                <li v-for="(item, index) in informations.data" :key="index">
                    <Item :item="item" />
                </li>
            </ul>
        </div>
      </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Item from './item.vue';
export default {
  name: "Information",
  components: {
    Item
  },
  data() {
    return {
      informations: {},
    };
  },
  computed: {
      ...mapGetters({
          topPage: "common/topPage",
      })
  },
  created(){
      this.informations = this.topPage.information
  },
  watch: {
      topPage(data){
        this.informations = data.information
      }
  },
};
</script>