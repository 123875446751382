<template>
    <category />
</template>
<script>
    import category from '@components/pc/category/category.vue'

    export default {
        name: "NavLeft",
        components: { 
            category
        },
    }
</script>