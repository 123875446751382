<template>
  <div class="text-center">
    <Button class="btn-apply-teacher" :name="_name" @handleClick="handleClick" v-if="isApply"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isVisible: false,
      isApply: true
    };
  },
  computed: {
    ...mapGetters({
      user: "member/getUser"
    }),
    _name() {
      return this.$t("top_page.apply_to_teacher");
    },
  },
  created() {
    this.checkApply();
  },
  methods: {
    handleClick() {
      this.$router.push("/to-sell");
    },
    actionCancel() {
      this.isVisible = false;
    },
    checkApply(){
       if(this.user.teacher 
        && this.user.teacher.status !== 'inactive'){
          this.isApply = false;
        }
    }
  },
  watch:{
    user(data){
      this.checkApply();
    }
  }
};
</script>